import { type ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import { useDebounce } from "~/hooks/use-debounce";
import { cn } from "~/utils/classnames";

import "./RangeSlider.css";
import type { RangeSliderProps } from "./RangeSlider.types";

export const RangeSlider = ({
  label,
  unit = "",
  min = 0,
  max = 9921,
  lowValue = min,
  highValue = max,
  minDifference = 1,
  onValueChange,
  onDefaultValue,
  className,
  ...props
}: RangeSliderProps) => {
  const isMounted = useRef(false);
  const [lowValueState, setLowValueState] = useState(lowValue);
  const [highValueState, setHighValueState] = useState(highValue);
  const [isClicked, setIsCLicked] = useState(false);

  const debouncedLowValue = useDebounce<number>(lowValueState, 250);
  const debouncedHighValue = useDebounce<number>(highValueState, 250);

  const lowValueInputRef = useRef<HTMLInputElement>(null);
  const highValueInputRef = useRef<HTMLInputElement>(null);

  const rangeDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLowValueState(lowValue);
    setHighValueState(highValue);
  }, [lowValue, highValue]);

  const getPercentage = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  const handleLowValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Math.min(Number(event.target.value), highValueState - 1);
      if (
        highValueState - value < minDifference ||
        Number.isNaN(value) ||
        value.toString().length === -1
      ) {
        return;
      }
      setLowValueState(value);
    },
    [highValueState, minDifference]
  );

  const handleHighValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Math.max(Number(event.target.value), lowValueState + 1);
      if (
        value - lowValueState < minDifference ||
        Number.isNaN(value) ||
        value.toString().length === -1
      ) {
        return;
      }
      setHighValueState(value);
    },
    [lowValueState, minDifference]
  );

  const updateRangeWidth = useCallback(() => {
    const minPercent = getPercentage(lowValueState);
    const maxPercent = getPercentage(highValueState);

    if (rangeDivRef.current) {
      rangeDivRef.current.style.left = `${minPercent}%`;
      rangeDivRef.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [lowValueState, highValueState, getPercentage]);

  useEffect(() => {
    updateRangeWidth();
  }, [lowValueState, highValueState, updateRangeWidth]);

  // Return min and max values when their state changes
  useEffect(() => {
    const isDefaultValues = debouncedLowValue === min && debouncedHighValue === max;
    if (isMounted.current) {
      if (!isDefaultValues && !isClicked) {
        onValueChange({
          lowValue: debouncedLowValue,
          highValue: debouncedHighValue,
        });
      } else if (onDefaultValue) {
        onDefaultValue();
      }
    }
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLowValue, debouncedHighValue, isClicked]);

  return (
    <div className={cn("RangeSlider", className)} {...props}>
      <p className="RangeSlider-label">{label}</p>
      <input
        type="range"
        min={min}
        max={max}
        value={lowValueState}
        ref={lowValueInputRef}
        onChange={handleLowValueChange}
        onMouseDown={() => setIsCLicked(true)}
        onMouseUp={() => setIsCLicked(false)}
        onTouchStart={() => setIsCLicked(true)}
        onTouchEnd={() => setIsCLicked(false)}
        className={cn(
          "RangeSlider-thumb RangeSlider-thumb--zindex-3",
          lowValueState > max - 100 && "RangeSlider-thumb--zindex-5"
        )}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={highValueState}
        ref={highValueInputRef}
        onChange={handleHighValueChange}
        onMouseDown={() => setIsCLicked(true)}
        onMouseUp={() => setIsCLicked(false)}
        onTouchStart={() => setIsCLicked(true)}
        onTouchEnd={() => setIsCLicked(false)}
        className="RangeSlider-thumb RangeSlider-thumb--zindex-4"
      />
      <div className="RangeSlider-slider">
        <div className="RangeSlider-slider-track" />
        <div ref={rangeDivRef} className="RangeSlider-slider-range"></div>
      </div>
      <div className="RangeSlider-values-container">
        <span className="RangeSlider-value-left">{lowValueState}</span>
        <span className="RangeSlider-value-unit">{unit}</span>
        <span className="RangeSlider-value-right">{highValueState}</span>
        <span className="RangeSlider-value-unit">{unit}</span>
      </div>
    </div>
  );
};
