import type { Brand } from "~types/api/brand.types";
import type { CharacteristicFilter } from "~types/api/characteristic.types";
import type { Range } from "~types/api/range.types";

import { staticCharacteristicsData } from "~/config/characteristics";

export const getFiltersFromSearchParam = (param: string) => {
  const filters = param.split("__") || [param];

  return filters.map((filter) => {
    if (filter.includes("--")) {
      const [slug, range] = filter.includes("--") ? filter.split("--") : [filter, null];
      const [activeMinValue, activeMaxValue] = range?.split("~") || [null, null];

      return {
        slug,
        activeMinValue: parseInt(activeMinValue || ""),
        activeMaxValue: parseInt(activeMaxValue || ""),
      };
    } else if (filter.includes("~")) {
      const [activeMinValue, activeMaxValue] = filter.split("~") || [null, null];

      return {
        slug: "",
        activeMinValue: Math.floor(parseInt(activeMinValue || "0")),
        activeMaxValue: Math.ceil(parseInt(activeMaxValue || "0")),
      };
    }

    return { slug: filter, activeMinValue: null, activeMaxValue: null };
  });
};

export const getActiveFilters = ({
  searchParams,
  brands,
  ranges,
  characteristicFilters,
  currentCategorySlug,
}: {
  searchParams: URLSearchParams | null | undefined;
  brands?: Brand[] | null;
  ranges?: Range[] | null;
  characteristicFilters?: CharacteristicFilter[] | null | undefined;
  currentCategorySlug?: string | null | undefined;
}) => {
  if (!searchParams) {
    return {
      brands: null,
      ranges: null,
      rangesCompatibles: null,
      searchFilter: null,
      characteristics: null,
    };
  }

  // Stock filter
  const stockStringParam = searchParams.get("stock");
  const activeStockFiltersParams = stockStringParam
    ? getFiltersFromSearchParam(stockStringParam)
    : null;
  const activeStockFilters =
    activeStockFiltersParams?.map((filter) => ({
      ...filter,
      filterParam: "stock",
      filterLabel: `En stock`,
    })) || null;

  // Brands filters
  const brandStringParam = searchParams.get("marques");
  const brandFiltersParams = brandStringParam ? getFiltersFromSearchParam(brandStringParam) : null;
  const activeBrandFilters =
    brands
      ?.filter((brand) => brandFiltersParams?.map((filter) => filter.slug).includes(brand.slug))
      .map((brand) => ({
        ...brand,
        filterParam: "marques",
        filterLabel: `Marque ${brand.title}`,
      })) || null;

  // Ranges filters
  const rangeStringParam = searchParams.get("gammes");
  const activeRangeFiltersParams = rangeStringParam
    ? getFiltersFromSearchParam(rangeStringParam)
    : null;
  const activeRangeFilters =
    ranges
      ?.filter((range) =>
        activeRangeFiltersParams?.map((filter) => filter.slug).includes(range.slug)
      )
      .map((range) => ({
        ...range,
        filterParam: "gammes",
        filterLabel: `Gamme ${range.brandRange.title}`,
      })) || null;

  // Range compatible filters
  const rangeCompatibleStringParam = searchParams.get("gamme-compatible");
  const activeRangeCompatibleFiltersParams = rangeCompatibleStringParam
    ? getFiltersFromSearchParam(rangeCompatibleStringParam)
    : null;
  const activeRangeCompatibleFilters =
    ranges
      ?.filter((range) =>
        activeRangeCompatibleFiltersParams?.map((filter) => filter.slug).includes(range.slug)
      )
      .map((range) => ({
        ...range,
        filterParam: "gamme-compatible",
        filterLabel: `Compatible avec la gamme ${range.brandRange.title}`,
      })) || null;

  // Search filter
  const searchStringParam = searchParams.get("search");
  const activeSearchFiltersParams = searchStringParam
    ? getFiltersFromSearchParam(searchStringParam)
    : null;
  const activeSearchFilters =
    activeSearchFiltersParams?.map((filter) => ({
      ...filter,
      filterParam: "search",
      filterLabel: `Recherche: ${filter.slug}`,
    })) || null;

  // Price filter
  const priceStringParam = searchParams.get("price");
  const activePriceFiltersParams = priceStringParam
    ? getFiltersFromSearchParam(priceStringParam)
    : null;
  const activePriceFilters =
    activePriceFiltersParams?.map((filter) => ({
      ...filter,
      slug: "price",
      filterParam: "price",
      filterLabel: `Prix: ${filter.activeMinValue} € - ${filter.activeMaxValue} €`,
    })) || null;

  // Characteristics filters
  const characteristicStringParam = searchParams.get("filtres");
  const activeCharacteristicFiltersParams = characteristicStringParam
    ? getFiltersFromSearchParam(characteristicStringParam)
    : null;

  const activeCharacteristicFilters =
    activeCharacteristicFiltersParams
      ?.map((filter) => {
        // Get the characteristic filter data from the characteristics API reponse
        const characteristicFilter = characteristicFilters?.find(
          (characteristicFilter) => characteristicFilter.characteristic.slug === filter.slug
        );
        if (!characteristicFilter) {
          return null;
        }

        // Get static characteristic data from the config file (to get the unit)
        const staticCharacteristic = Object.values(
          staticCharacteristicsData.specifications.characteristics
        ).find(
          (staticCharacteristic) =>
            staticCharacteristic.slug === characteristicFilter.characteristic.slug
        );

        const unit =
          staticCharacteristic && "unit" in staticCharacteristic
            ? (staticCharacteristic.unit[
                currentCategorySlug as keyof typeof staticCharacteristic.unit
              ] ?? staticCharacteristic.unit["default"])
            : null;

        const filterLabel =
          filter.activeMinValue !== null && filter.activeMaxValue !== null && unit
            ? `${characteristicFilter.characteristic.title}: ${filter.activeMinValue}${
                unit ? ` ${unit}` : ""
              } - ${filter.activeMaxValue}${unit ? ` ${unit}` : ""}`
            : characteristicFilter.characteristic.title;

        return {
          ...staticCharacteristic,
          ...filter,
          title: characteristicFilter.characteristic.title,
          slug: characteristicFilter.characteristic.slug,
          isNumeric: characteristicFilter.characteristic.isNumeric,
          min: Math.floor(characteristicFilter.numeric?.min || 0),
          max: Math.ceil(characteristicFilter.numeric?.max || 0),
          filterParam: "filtres",
          filterLabel,
        };
      })
      .filter(Boolean) || null;

  // Return all filters
  return {
    // Filters with associated full objects taken from data
    brands: activeBrandFilters,
    ranges: activeRangeFilters,
    rangesCompatibles: activeRangeCompatibleFilters,
    searchFilter: activeSearchFilters,
    characteristics: activeCharacteristicFilters,
    price: activePriceFilters,
    stock: activeStockFilters,
    // Filters params with only slug and min max values taken from url params
    brandsParams: brandFiltersParams,
    rangesParams: activeRangeFiltersParams,
    rangesCompatibleParams: activeRangeCompatibleFiltersParams,
    searchFilterParams: activeSearchFiltersParams,
    characteristicsParams: activeCharacteristicFiltersParams,
    priceParams: activePriceFiltersParams,
    stockParams: activeStockFiltersParams,
  };
};
