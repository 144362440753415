import type { ComponentPropsWithoutRef } from "react";
import { RiCloseLargeLine } from "react-icons/ri";

import { cn } from "~/utils/classnames";

interface FilterTagProps extends ComponentPropsWithoutRef<"div"> {
  onCrossIconClick?: () => void;
}

export const FilterTag = ({ onCrossIconClick, children, className, ...props }: FilterTagProps) => {
  return (
    <div
      className={cn(
        "flex items-center gap-3 rounded-full bg-primary px-3 py-1.5 text-xs font-medium leading-none",
        className
      )}
      {...props}
    >
      <span className="leading-none">{children}</span>
      <button onClick={onCrossIconClick}>
        <RiCloseLargeLine className="size-2.5" />
      </button>
    </div>
  );
};
