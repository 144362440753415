import { useLocation, useNavigation, useSearchParams } from "@remix-run/react";
import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import type { Brand } from "~types/api/brand.types";
import type { Category } from "~types/api/category.types";
import type { CharacteristicFilter } from "~types/api/characteristic.types";
import type { Range } from "~types/api/range.types";

import { Accordion } from "~/components/Accordion";
import { RangeSlider } from "~/components/RangeSlider";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/cards/card";
import { FilterTag } from "~/components/ui/filter-tag";
import { Checkbox } from "~/components/ui/forms/checkbox";
import { Input } from "~/components/ui/forms/input";
import { Text } from "~/components/ui/typography";
import { categoriesWithoutBrandsFilters, categoriesWithRanges } from "~/config/navigation";
import { getFormatedCharacteristicsForFilters } from "~/services/characteristics";
import { getActiveFilters } from "~/services/filters";
import { cn } from "~/utils/classnames";

interface FilterSidebarProps {
  currentCategory: Category | null;
  characteristicFilters: CharacteristicFilter[] | null | undefined;
  priceRange: { minPrice: string; maxPrice: string } | null;
  isStockFilterAvailable?: boolean | null;
  brands: Brand[] | null;
  ranges?: Range[] | null;
  className?: string;
}

export const FilterSidebar = ({
  currentCategory,
  characteristicFilters,
  priceRange,
  isStockFilterAvailable,
  brands,
  ranges,
  className,
}: FilterSidebarProps) => {
  const navigation = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>(searchParams.get("search") || "");

  // const searchInputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();

  useEffect(() => {
    setSearchValue("");
  }, [location]);

  // Get active filters in URL params with custom Hook useActiveFilters.
  const activeFilters = getActiveFilters({
    searchParams,
    brands,
    ranges: ranges || null,
    characteristicFilters,
    currentCategorySlug: currentCategory?.slug || null,
  });

  const mergedActiveFilters = [
    ...(activeFilters.stock || []),
    ...(activeFilters.searchFilter || []),
    ...(activeFilters.brands || []),
    ...(activeFilters.ranges || []),
    ...(activeFilters.characteristics || []),
    ...(activeFilters.price || []),
  ];

  const formatedCharacteristicFilters = getFormatedCharacteristicsForFilters({
    categorySlug: currentCategory?.slug || null,
    characteristicFilters: characteristicFilters || [],
  });

  const stockFilters = isStockFilterAvailable
    ? {
        title: "Stocks",
        param: "stock",
        characteristics: [
          {
            title: "En stock",
            slug: "1",
            technicalName: "1",
            min: 0,
            max: 1,
            matchingCount: 1,
            type: "boolean",
            unit: null,
          },
        ],
      }
    : null;

  const brandFilters = {
    title: "Marques",
    param: "marques",
    characteristics:
      brands?.map((brand) => ({
        title: brand.title || "",
        technicalName: brand.slug || "",
        slug: brand.slug || "",
        min: 0,
        max: 1,
        matchingCount: 1,
        type: "boolean",
        unit: null,
      })) || [],
  };

  const rangeFilters = {
    title: "Gammes",
    param: "gammes",
    characteristics:
      ranges?.map((range) => ({
        title: range.brandRange.title || "",
        slug: range.slug || "",
        technicalName: range.slug || "",
        min: 0,
        max: 1,
        matchingCount: 1,
        type: "boolean",
        unit: null,
      })) || [],
  };

  const priceFilter = {
    title: "Prix",
    param: "price",
    characteristics: [
      {
        title: "Prix",
        slug: "price",
        technicalName: "price",
        min: Math.floor(parseFloat(priceRange?.minPrice || "0")),
        max: Math.ceil(parseFloat(priceRange?.maxPrice || "0")),
        matchingCount: 1,
        type: "value",
        unit: "€",
      },
    ],
  };

  const getStockFilterIndex = () => {
    return 0;
  };
  const getBrandFilterIndex = (categorySlug: string) => {
    if (categoriesWithRanges.includes(categorySlug)) {
      return 4;
    }
    return 2;
  };
  const getRangeFilterIndex = (categorySlug: string) => {
    if (categoriesWithRanges.includes(categorySlug)) {
      return 5;
    }
    return 0;
  };
  const getPriceFilterIndex = () => {
    return 999;
  };

  const formatedFilters = [...formatedCharacteristicFilters];
  if (stockFilters) {
    formatedFilters.splice(getStockFilterIndex(), 0, stockFilters);
  }
  if (currentCategory?.slug && !categoriesWithoutBrandsFilters.includes(currentCategory.slug)) {
    formatedFilters.splice(getBrandFilterIndex(currentCategory.slug || ""), 0, brandFilters);
  }
  formatedFilters.splice(getRangeFilterIndex(currentCategory?.slug || ""), 0, rangeFilters);
  if (priceRange && priceRange.minPrice !== priceRange.maxPrice) {
    formatedFilters.splice(getPriceFilterIndex(), 0, priceFilter);
  }

  // Function to handle filter changement.
  const handleFilterChange = ({
    param,
    slug,
    type,
    isChecked,
    lowValue,
    highValue,
  }: {
    param: string;
    slug: string;
    type: string;
    isChecked?: boolean;
    lowValue?: number;
    highValue?: number;
  }) => {
    // Get all current filter params from URL.
    const filterParams = searchParams.get(param)?.split("__") || [];
    let updatedFilterParams: string[] = [];

    if (param === "price") {
      const newFilterParam = `${lowValue}~${highValue}`;
      // const filterParamsWithoutCurrent = filterParams.filter(
      //   (filterParam) => filterParam.split("--")[0] !== slug
      // );
      updatedFilterParams = [newFilterParam];
    }
    // Checkbox filters.
    else if (type === "boolean") {
      if (isChecked && !filterParams.includes(slug)) {
        updatedFilterParams = [...filterParams, slug];
      } else if (!isChecked && filterParams.includes(slug)) {
        updatedFilterParams = filterParams.filter((filterParam) => filterParam !== slug);
      } else {
        return;
      }
    }
    // Slider filters.
    else if (type === "value") {
      const newFilterParam = `${slug}--${lowValue}~${highValue}`;
      const filterParamsWithoutCurrent = filterParams.filter(
        (filterParam) => filterParam.split("--")[0] !== slug
      );
      updatedFilterParams = [...filterParamsWithoutCurrent, newFilterParam];
    }
    // Unknown filters.
    else {
      updatedFilterParams = filterParams;
    }

    // Update search params with new filter params.
    return setSearchParams(
      (searchParams) => {
        searchParams.delete("page");
        if (updatedFilterParams.length === 0) {
          searchParams.delete(param);
        } else {
          const updatedFilterParamsString = updatedFilterParams.join("__");
          searchParams.set(param, updatedFilterParamsString);
        }
        return searchParams;
      },
      { preventScrollReset: true, replace: true }
    );
  };

  // Function to handle filter deletion.
  const handleFilterDelete = ({ param, slug }: { param: string; slug: string }) => {
    const filterParams = searchParams.get(param)?.split("__") || [];
    // Remove the filter value from the filter params.
    let updatedFilterParams = filterParams.filter(
      (filterParam) => filterParam.split("--")[0] !== slug.split("--")[0]
    );

    if (param === "price") {
      updatedFilterParams = [];
    }

    if (param === "search") {
      setSearchValue("");
    }

    return setSearchParams(
      (searchParams) => {
        searchParams.delete("page");
        // If there are no filters, remove the filter param.
        if (updatedFilterParams.length === 0) {
          searchParams.delete(param);
        } else {
          // Otherwise, update the filter param with the new value.
          const updatedFilterParamsString = updatedFilterParams.join("__");
          searchParams.set(param, updatedFilterParamsString);
        }
        return searchParams;
      },
      { preventScrollReset: true, replace: true }
    );
  };

  const handleSearch = (searchValue: string) => {
    // const searchValue = event.target.value;
    return setSearchParams(
      (searchParams) => {
        searchParams.delete("page");
        if (searchValue.length === 0) {
          searchParams.delete("search");
        } else {
          searchParams.set("search", searchValue);
        }
        return searchParams;
      },
      { preventScrollReset: true, replace: true }
    );
  };

  const isGlobalPending = navigation.state === "submitting" || navigation.state === "loading";
  return (
    <Card
      className={cn(
        "transition-opacity",
        isGlobalPending
          ? "pointer-events-none cursor-progress opacity-60 *:pointer-events-none"
          : "opacity-100",
        className
      )}
    >
      <Text as="div" className="text-center font-medium">
        Affinez votre recherche
      </Text>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch(searchValue);
        }}
      >
        <Input
          placeholder="Rechercher"
          value={searchValue || ""}
          icon={<RiSearchLine />}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
          className="mt-6"
        />
      </form>

      {mergedActiveFilters && mergedActiveFilters.length > 0 ? (
        <div className="mt-6">
          <Text as="div" size="sm" className="font-medium">
            Filtres actifs :
          </Text>
          <div className="mt-2 flex flex-wrap items-center justify-start gap-2">
            {mergedActiveFilters.map((filter) =>
              filter ? (
                <FilterTag
                  key={filter.slug}
                  onCrossIconClick={() =>
                    handleFilterDelete({
                      param: filter.filterParam,
                      slug: filter.slug,
                    })
                  }
                >
                  {filter.filterLabel}
                </FilterTag>
              ) : null
            )}
          </div>
        </div>
      ) : null}

      <div className="mt-6">
        {formatedFilters.map((filterCategory) => {
          if (filterCategory.characteristics.length === 0) {
            return null;
          }
          return (
            <FilterCategory
              key={filterCategory.title}
              filterCategory={filterCategory}
              handleFilterChange={handleFilterChange}
            />
          );
        })}
      </div>
    </Card>
  );
};

export const FilterCategory = ({
  filterCategory,
  handleFilterChange,
}: {
  filterCategory: ReturnType<typeof getFormatedCharacteristicsForFilters>[0];
  handleFilterChange: (params: any) => void;
}) => {
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const MAX_CHARACTERISTICS = 5;

  const characteristics = filterCategory.characteristics.filter(
    (characteristic) =>
      !(characteristic?.type === "value" && characteristic.min === characteristic.max)
  );

  if (characteristics.length === 0) {
    return null;
  }

  return (
    <Accordion title={filterCategory.title} key={filterCategory.title} defaultOpen className="mt-6">
      <>
        {characteristics.map((characteristic, index) => {
          if (
            index > MAX_CHARACTERISTICS - 1 &&
            !isOpen &&
            characteristics.length - MAX_CHARACTERISTICS > 2
          ) {
            return null;
          }
          if (!characteristic || (characteristic.min === 0 && characteristic.max === 0)) {
            return null;
          }

          if (characteristic.type === "value") {
            return (
              <RangeSlider
                className="mt-3"
                id={`filter-${characteristic.slug}`}
                label={characteristic.title}
                unit={characteristic.unit || ""}
                key={`${characteristic.slug}-${characteristic.min}-${characteristic.max}-${characteristic.title}`}
                min={characteristic.min}
                max={characteristic.max}
                lowValue={Math.floor(Math.max(characteristic.min) || 0)}
                highValue={Math.ceil(Math.min(characteristic.max) || 0)}
                onValueChange={({ lowValue, highValue }) =>
                  handleFilterChange({
                    param: filterCategory.param,
                    slug: characteristic.slug,
                    type: "value",
                    lowValue,
                    highValue,
                  })
                }
              />
            );
          }
          if (characteristic.type === "boolean") {
            return (
              <Checkbox
                className="mt-3 text-sm font-medium text-grey-600"
                key={characteristic.slug}
                id={`filter-${filterCategory.title}-${filterCategory.param}-${characteristic.slug}-${characteristic.type}`}
                name={`filter-${filterCategory.title}-${filterCategory.param}-${characteristic.slug}-${characteristic.type}`}
                label={characteristic.title}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleFilterChange({
                    param: filterCategory.param,
                    slug: characteristic.slug,
                    type: "boolean",
                    isChecked: e.target.checked,
                  })
                }
                checked={
                  searchParams
                    .get(filterCategory.param)
                    ?.split("__")
                    .includes(characteristic.slug) || false
                }
              />
            );
          }
          return null;
        })}
        {characteristics.length > MAX_CHARACTERISTICS &&
        characteristics.length - MAX_CHARACTERISTICS > 2 ? (
          <Button variant="secondary" size="xs" className="mt-4" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? "Voir moins -" : "Voir plus +"}
          </Button>
        ) : null}
      </>
    </Accordion>
  );
};
