import { CloseButton, Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useSearchParams } from "@remix-run/react";
import Autoplay from "embla-carousel-autoplay";
import { type ComponentPropsWithoutRef, useState } from "react";
import { RiCloseLargeLine, RiEqualizerLine } from "react-icons/ri";
import type { Brand } from "~types/api/brand.types";
import type { Category } from "~types/api/category.types";
import type { CharacteristicFilter } from "~types/api/characteristic.types";
import type { Range } from "~types/api/range.types";
import type { SessionData } from "~types/app/session.types";

import { FilterSidebar } from "~/components/filter-sidebar";
import { HtmlParser } from "~/components/HtmlParser";
import { Pagination } from "~/components/Pagination";
import { Button } from "~/components/ui/button";
import { RangeCard } from "~/components/ui/cards/range-card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "~/components/ui/carousels/carousel";
import { Select } from "~/components/ui/forms/select";
import { Wrapper } from "~/components/ui/layout/wrapper";
import { Heading, Text } from "~/components/ui/typography";
import { DEFAULT_ITEMS_PER_PAGE_OPTION, ITEMS_PER_PAGE_OPTIONS } from "~/config/item-list";
import { usePagination } from "~/hooks/use-pagination";
import { useSort } from "~/hooks/use-sort";
import { getActiveFilters } from "~/services/filters";
import { cn } from "~/utils/classnames";

interface ItemListProps extends ComponentPropsWithoutRef<typeof Wrapper> {
  category: Category | null;
  totalItems: number;
  totalItemsText: string;
  characteristicFilters: CharacteristicFilter[] | null | undefined;
  sortingOptions: any[];
  defaultSortingOption: string;
  comparator: SessionData["comparator"];
  brands: Brand[] | null;
  filteredRanges?: Range[] | null;
  ranges?: Range[] | null;
  priceRange?: { minPrice: string; maxPrice: string } | null;
  isStockFilterAvailable?: boolean | null;
  isUserConnected: boolean;
  isEmpty?: boolean;
}

export const ItemList = ({
  category,
  totalItems,
  totalItemsText,
  characteristicFilters,
  brands,
  ranges,
  filteredRanges,
  priceRange,
  isStockFilterAvailable,
  sortingOptions,
  defaultSortingOption,
  comparator,
  isUserConnected,
  isEmpty = false,
  children,
  className,
  ...props
}: ItemListProps) => {
  const [searchParams] = useSearchParams();
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  const { currentSortParam, handleSortChange } = useSort({
    defaultSortParam: defaultSortingOption,
  });

  const { updateItemsPerPage } = usePagination({
    totalItems,
    itemsPerPageFallback: parseInt(DEFAULT_ITEMS_PER_PAGE_OPTION),
  });

  const activeFilters = getActiveFilters({
    searchParams,
    brands,
    ranges: filteredRanges || null,
    characteristicFilters,
  });

  return (
    <>
      <div className="sticky left-0 right-0 top-[calc(100vh-3.5rem)] z-20 flex items-center justify-center border-t border-grey-200 bg-white xl:hidden">
        <Button
          variant="ghost"
          onClick={() => setIsMobileFiltersOpen(true)}
          className="h-14 w-full rounded-none"
          aria-label="Ouvrir les filtres"
          icon={<RiEqualizerLine />}
        >
          Filtrer
        </Button>
      </div>

      <Wrapper className={cn("grid grid-cols-1 gap-4 pb-12 xl:grid-cols-4", className)} {...props}>
        <div className="col-span-1">
          <FilterSidebar
            currentCategory={category || null}
            characteristicFilters={characteristicFilters}
            priceRange={priceRange || null}
            isStockFilterAvailable={isStockFilterAvailable || null}
            brands={brands || null}
            ranges={filteredRanges || null}
            className="hidden xl:block"
          />
        </div>

        <Dialog
          as="div"
          open={isMobileFiltersOpen}
          onClose={setIsMobileFiltersOpen}
          className="relative z-40 xl:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />
          <div className="fixed inset-0 z-40">
            <DialogPanel
              transition
              className="relative ml-auto h-full w-full overflow-y-auto bg-white shadow-xl transition duration-300 ease-in-out data-[closed]:translate-y-full sm:max-w-sm sm:data-[closed]:translate-x-full sm:data-[closed]:translate-y-0"
            >
              <CloseButton
                as={Button}
                variant="ghost"
                iconOnly
                icon={<RiCloseLargeLine />}
                className="absolute right-2 top-2 z-40"
              >
                Fermer les filtres
              </CloseButton>
              <FilterSidebar
                currentCategory={category || null}
                characteristicFilters={characteristicFilters}
                priceRange={priceRange || null}
                isStockFilterAvailable={isStockFilterAvailable || null}
                brands={brands || null}
                ranges={filteredRanges || null}
                className="rounded-none border-none shadow-none"
              />
            </DialogPanel>
          </div>
        </Dialog>

        <div className="col-span-1 xl:col-span-3">
          {activeFilters.ranges && activeFilters.ranges.length === 1 ? (
            <>
              <div className="mb-8 flex flex-col items-center gap-8 md:flex-row">
                <img
                  className="h-32 w-32 shrink-0 rounded-lg object-cover sm:h-48 sm:w-48"
                  src={activeFilters.ranges[0]?.illustrationUrl}
                  alt={activeFilters.ranges[0]?.brandRange?.title}
                />
                <div>
                  <Heading as="h2">
                    {activeFilters.ranges[0]?.brand?.title} -{" "}
                    {activeFilters.ranges[0]?.brandRange?.title}
                  </Heading>
                  <HtmlParser className="mt-2" html={activeFilters.ranges[0]?.description || ""} />
                  <Button
                    as="link"
                    to={`/systemes-de-fixation/gamme/${activeFilters.ranges[0]?.slug}`}
                    variant="secondary"
                  >
                    En savoir plus
                  </Button>
                </div>
              </div>
            </>
          ) : activeFilters.brands &&
            activeFilters.brands.length === 1 &&
            activeFilters.brands[0]?.description &&
            activeFilters.brands[0]?.description?.length > 0 ? (
            <>
              <div className="mb-8 flex flex-col items-center gap-8 md:flex-row">
                <img
                  className="h-32 w-32 shrink-0 rounded-lg object-contain sm:h-40 sm:w-48"
                  src={activeFilters.brands[0]?.images?.[0]?.url}
                  alt={activeFilters.brands[0]?.title}
                />
                <div>
                  <Heading as="h2"> {activeFilters.brands[0]?.title}</Heading>
                  <HtmlParser className="mt-2" html={activeFilters.brands[0]?.description || ""} />
                  <Button
                    as="link"
                    to={`/nos-partenaires/${activeFilters.brands[0]?.slug}`}
                    variant="secondary"
                  >
                    En savoir plus
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              {ranges && ranges.length > 0 ? (
                <>
                  <Heading as="h2">Nos gammes</Heading>
                  <Carousel
                    opts={{
                      align: "center",
                      breakpoints: {
                        "(min-width: 640px)": { align: "start" },
                      },
                    }}
                    plugins={[
                      Autoplay({
                        delay: 4000,
                        stopOnInteraction: false,
                        stopOnMouseEnter: true,
                      }),
                    ]}
                    className={cn(className)}
                  >
                    <CarouselPrevious />
                    <CarouselContent
                      containerClassName="overflow-visible sm:overflow-hidden"
                      className="-mx-0 -mb-60 py-2 pb-64"
                    >
                      {ranges.map((range) => (
                        <CarouselItem
                          key={range.id}
                          className={cn("px-2 *:h-full md:basis-1/2 lg:basis-1/3")}
                        >
                          <RangeCard
                            range={range}
                            comparator={comparator}
                            isUserConnected={isUserConnected}
                          />
                        </CarouselItem>
                      ))}
                    </CarouselContent>
                    <CarouselNext />
                  </Carousel>
                  <Button as="link" to="/systemes-de-fixation/gammes" className="mx-auto mt-2">
                    Toutes les gammes
                  </Button>
                </>
              ) : null}
            </>
          )}

          {isEmpty ? null : (
            <div className="mb-4 flex flex-wrap items-center gap-x-8 gap-y-3">
              <Text size="sm" muted className="font-medium">
                {totalItemsText}
              </Text>
              <div className="flex flex-wrap items-center gap-x-4 gap-y-2 xl:ml-auto">
                <Select
                  id="sort-by"
                  name="sort-by"
                  label="Trier par"
                  options={sortingOptions}
                  onChange={(e) => handleSortChange(e.currentTarget.value)}
                  value={currentSortParam}
                  className="flex items-center gap-x-2 text-sm [&_div]:mt-0 [&_label]:px-0 [&_label]:font-medium [&_label]:text-grey-500"
                />
                <Select
                  name="items-per-page"
                  id="items-per-page"
                  label="Articles par page"
                  options={ITEMS_PER_PAGE_OPTIONS}
                  onChange={(e) => updateItemsPerPage(parseInt(e.currentTarget.value))}
                  value={
                    searchParams.get("items-per-page") || parseInt(DEFAULT_ITEMS_PER_PAGE_OPTION)
                  }
                  className="flex items-center gap-x-2 text-sm [&_div]:mt-0 [&_label]:px-0 [&_label]:font-medium [&_label]:text-grey-500"
                />
              </div>
            </div>
          )}
          {isEmpty ? (
            children
          ) : (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3">
              {children}
            </div>
          )}
          <Pagination
            totalItems={totalItems}
            itemsPerPage={parseInt(DEFAULT_ITEMS_PER_PAGE_OPTION)}
          />
          {category?.description || category?.shortDescription ? (
            <HtmlParser html={category.description || category.shortDescription} className="mt-8" />
          ) : null}
        </div>
      </Wrapper>
    </>
  );
};
